<template>
  <div class="bg-beigeDark">
    <p
      class="hidden 3xl:block absolute text-6xl text-white transform rotate-90 left-12 top-0 origin-bottom-left font-bold tracking-wide opacity-40"
    >
      WOW! Your screen is massive :)
    </p>
    <div class="maxWidthLimitter 3xl:rounded-t-2xl">
      <HeaderMain />
      <TextStrip
        class="processBar"
        text="OUR PROCESS"
      />
    </div>

    <OurProcess class="max-w-screen-3xl bg-mainBg mx-auto" />
    <div class="maxWidthLimitter 3xl:rounded-b-2xl">
      <SolutionsText />

      <OurBrands class="my-24" />

      <TextStrip text="CONTACT US" />

      <ContactForm />

      <footer class="mt-20 bg-gray-800 px-4 py-8 relative z-40">
        <p class="text-white text-center font-semibold opacity-90 text-sm mb-2">
          RED Smart Applications Ltd
        </p>
        <p class="text-white text-center text-sm tracking-wide opacity-80">
          15 Rockstone Place, Southampton, England, SO15 2EP<br />
          Registered in England & Wales No. 11441468<br />
          VAT Registration No. 315892485<br />
        </p>
        <hr class="border-white mx-8 my-6 opacity-30" />
        <p class="text-white text-center opacity-50 text-xs tracking-wide">
          Copyright © 2025 RED Smart Applications Ltd. All Rights Reserved.
        </p>
      </footer>
      <ScrollerDot />
    </div>
  </div>
</template>

<script>
import HeaderMain from '@/components/HeaderMain'
import TextStrip from '@/components/TextStrip';
import ScrollerDot from '@/components/ScrollerDot';
import OurBrands from '@/components/OurBrands';
import SolutionsText from '@/components/SolutionsText';
import ContactForm from '@/components/ContactForm';
import OurProcess from '@/components/OurProcess';
import GridElement from '@/components/GridElement';

export default {
  name: 'App',
  components: {
    HeaderMain,
    TextStrip,
    ScrollerDot,
    OurBrands,
    SolutionsText,
    ContactForm,
    OurProcess,
    GridElement,
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  @apply px-6 py-2 text-xs tracking-wider rounded-full  inline-block cursor-pointer text-white transition-colors duration-500;

  box-shadow: 0px 5px 20px -15px rgba(0, 0, 0, 1);

  &.btn-primary {
    background-color: #c61e1e;

    &:hover,
    &:focus {
      background-color: darken(#c61e1e, 7);
    }
  }

  &.btn-secondary {
    background-color: #a99b7e;

    &:hover,
    &:focus {
      background-color: darken(#a99b7e, 7);
    }
  }

  &:disabled {
    @apply bg-gray-500 cursor-not-allowed;


    &:hover {
      @apply bg-gray-600;
    }
  }

  &.block-display {
    @apply block;
  }
}

@screen xl {
  .btn {
    @apply px-10 py-2.5 text-sm font-semibold;
  }
}

.label {
  @apply font-semibold text-gray-700 text-sm;
}

.input {
  @apply px-4 py-2 block rounded-md mt-3 mb-5 w-full;

  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);

  &:hover,
  &:focus {
    outline-color: #a99b7e;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.maxWidthLimitter {
  @apply max-w-screen-3xl relative mx-auto overflow-x-hidden bg-mainBg 3xl:shadow-2xl;
}
</style>
